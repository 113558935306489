body
{
    width: 100%;
    margin: 0px;
    padding: 0px;
    background-color: #fafafa;
    position: relative;
}
header {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height:60px ;
    background-image: url('../images/logo.png');
    background-repeat: no-repeat;
    background-position: 20px 20px;
}
.flex-buttons
{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.flex-buttons button
{
    margin-right: 10px;
    margin-left: 10px;
}
.full-container
{
    display: block;
    position: absolute;
    top:80px;
    left: 0px;
    width: 100%;
    height: 100%;
}
.full-container .content
{
    position: absolute;
    top: 30%;
    display: block;
    width: 100%;
}
.full-container .content p
{
    margin-top: 20px;
}
.input-error
{
    display: block;
    color: crimson;
    font-size: 10px;
    text-align: left;
}
.input-error::before
{
    content:" * "
}
p.branded,div.branded
{
    padding-top: 70px;
    background:url('../images/logo-white.png');
    background-position: top center;
    background-repeat: no-repeat;
    text-align: center;
    display: inline-block;
    margin-bottom: 20px;
}
button.branded,a.branded
{
    background:#BC2B8B;
    color: #FFF;
    font-size: 12px;
    text-transform: uppercase;
    border:none;
    border-radius: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    min-width: 150px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
    outline: none;
    text-decoration: none;
}

button.default,a.default
{
    background:#CCC;
    color: #333;
    font-size: 12px;
    text-transform: uppercase;
    border:none;
    border-radius: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    min-width: 150px;
    outline: none;
    text-decoration: none;
}
button.branded:hover,a.branded:hover,button.default:hover,a.default:hover
{
    opacity: 0.9
}
button.branded:disabled,a.branded:disabled,button.default:disabled,a.default:disabled
{
    opacity: 0.5;
    box-shadow: none;
}
button.branded:active,a.branded:active
{
    opacity: 1;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.5);
}
.estimator-container
{
    position: absolute;
    top:80px;
    left: 0px;
    width: 100%;
}
.estimator-container .content
{
    position: absolute;
    width: 100%;
    top: 50px;
    padding: 40px;
}
.estimator-container .content h1
{
    display: block;
    text-align: center;
    font-size: 26px;
    margin-bottom: 40px;
}
.estimator-container .question
{
    display: block;
    background:#FFF;
    padding: 20px;
    border-radius: 10px;
    height: 100%;
    box-shadow: 1px 1px 2px rgba(0,0,0,0.2);
    cursor: pointer;
    position: relative;
    padding-bottom: 100px;
    border: solid 2px #FFF;
}
.estimator-container .question.disabled
{
    cursor: default;
}
.margin-bottom
{
    margin-bottom: 20px;
}
.estimator-container .question.selected
{
    border-color: #BC2B8B;
}
.estimator-container .question.disabled.selected
{
    border: solid 2px #CCC;
}
.estimator-container .question.img-bottom,.estimator-container .thank-you .img-bottom.img-bottom
{
    display: flex;
    flex-direction: column;
}
.estimator-container .question h2
{
    display: block;
    text-align: center;
    font-size: 18px;
}
.estimator-container .question p
{
    text-align: center;
    font-size: 13px;
    color: #333;
}
.estimator-container .question .divider
{
    margin-top: 20px;
    border-top: solid 1px rgba(0,0,0,0.1);
    text-transform: uppercase;
    font-size: 12px;
    padding-top: 20px;
    width: 90%;
    display: block;
    height: 100px;
    position: absolute;
    left: 20px;
    bottom:0px;
}
.estimator-container .question .divider label
{
    color:#999;
    font-weight: 500;
}
.estimator-container .question .image
{
    display: block;
    height: 150px;
    text-align: center;
}
.estimator-container .thank-you .image
{
    display: block;
    height: 250px;
    text-align: center;
}
.estimator-container .thank-you .cta-buttons
{
    margin-top: 40px;
}
.estimator-container .question .image img,.estimator-container .thank-you .image img
{
    display: inline-block;
    width:100px;
    height: 100px;
    margin-top: 25px;
}
.estimator-container .cta-container
{
    display: block;
    margin-top: 40px;
}
.cta-second
{
    text-align: right;
}
.cta-first
{
    text-align: left;
}
.estimator-container .question .image.b-round img,.estimator-container .thank-you .image.b-round img
{
    border-radius: 100%;
}
.estimator-container .question .image.left,.estimator-container .thank-you .image.left
{
    float: left;
    width: 30%;
}
.estimator-container .question .question-info.left,.estimator-container .thank-you .thank-you-info.left
{
    float: right;
    width: 65%;
}
.estimator-container .question .image.right,.estimator-container .thank-you .image.right
{
    float: right;
    width: 30%;
}
.estimator-container .question .question-info.right,.estimator-container .thank-you .thank-you-info.right
{
    float: left;
    width: 65%;
}
.estimator-container .question .image.bottom,.estimator-container .thank-you .image.bottom
{
    order:2
}
.estimator-container .question .question-info.bottom,.estimator-container .thank-you .thank-you-info.bottom
{
    order:1
}
.estimator-container .question .question-info.bottom::after,.estimator-container .thank-you .thank-you-info.bottom::after
{
    clear:both;
    display: block;
    content:""
}
.estimator-container .question:hover
{
    box-shadow: 0px 5px 20px rgba(0,0,0,0.2);
}
.estimator-container .question .image.web
{
    background: url('../images/web.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100px 100px;
}
.estimator-container .thank-you .image.web
{
    background: url('../images/web.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 200px 200px;
}
.estimator-container .thank-you .image.android
{
    background: url('../images/Android.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 200px 200px;
}
.estimator-container .thank-you .image.apple
{
    background: url('../images/apple.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 200px 200px;
}
.estimator-container .question .image.android
{
    background: url('../images/Android.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100px 100px;
}
.estimator-container .question .image.apple
{
    background: url('../images/apple.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100px 100px;
}
@media (max-width:1024px)
{
    .estimator-container .question .divider
    {
        font-size: 9px;
    }
    .estimator-container .question .image.left,.estimator-container .thank-you .image.left
    {
        width: 40%;
    }
    .estimator-container .question .question-info.left,.estimator-container .thank-you .thank-you-info.left
    {
        width: 55%;
    }
    .estimator-container .question .image.right,.estimator-container .thank-you .image.right
    {
        width: 40%;
    }
    .estimator-container .question .question-info.right,.estimator-container .thank-you .thank-you-info.right
    {
        width: 55%;
    }
}
ul.application-process
{
    list-style: none;
    display: flex;
    flex-direction: row;
    margin:0px;
    padding: 0px;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    text-align: center;
    margin-top: 30px;
}
ul.application-process li
{
    padding-top: 20px;
    font-size: 10px;
    color: #d9d6d6;
    text-align: center;
    flex:1;
    position: relative;
}
ul.application-process li.current
{
    color: #BC2B8B;
    font-weight: bold;
}
ul.application-process li::before
{
    display: block;
    width: 50%;
    position: absolute;
    top: 10px;
    left: 0px;
    background:rgba(204,204,204,0.2);
    height: 1px;
    content:""
}
ul.application-process li.partial::before,ul.application-process li.complete::after,ul.application-process li.complete::before
{
    background: #BC2B8B;
}
ul.application-process li::after
{
    display: block;
    width: 50%;
    position: absolute;
    top: 10px;
    right: 0px;
    background:rgba(204,204,204,0.2);
    height: 1px;
    content:""
}
ul.application-process li div::after
{
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background:#e8e5e5;
    content:"";
    position: absolute;
    top: 5px;
    left: 49%;
    z-index: 1;
}
ul.application-process li.complete div::after,ul.application-process li.partial div::after
{
    background: #BC2B8B;
}
ul.application-process li.current div::after
{
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background:#FFF;
    border:solid 2px #BC2B8B;
    content:"";
    position: absolute;
    top: 5px;
    left: 49%;
    z-index: 1;
}
ul.application-process li:first-child::before,ul.application-process li:last-child::after
{
    display: none;
}
button.loading
{
    position: relative;
}
button.loading:disabled::after
{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #FFF;
    opacity: 0.6;
    position: absolute;
    top: 10px;
    right: 10px;
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
    content:""
}
button.loading:disabled::before
{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #FFF;
    opacity: 0.6;
    position: absolute;
    top: 10px;
    right: 10px;
    content:"";
    
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
    -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}
.PhoneInput {
    border: 0px;
    background-color: transparent;
}
.PhoneInputInput {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
}
.PhoneInputInput::placeholder {
    color: #6c757d;
}
@-webkit-keyframes sk-bounce {
    0%, 100% { -webkit-transform: scale(0.0) }
    50% { -webkit-transform: scale(1.0) }
  }
  
  @keyframes sk-bounce {
    0%, 100% { 
      transform: scale(0.0);
      -webkit-transform: scale(0.0);
    } 50% { 
      transform: scale(1.0);
      -webkit-transform: scale(1.0);
    }
  }
  @media (max-width:414px)
  {
    .flex-buttons
    {
        flex-direction: column-reverse;
    }
    .flex-buttons button
    {
        width: 100%;
        margin: 0px;
        margin-bottom: 20px;
    }
    .estimator-container .cta-container
    {
        display: flex;
        flex-direction: column;
    }
    .cta-second
    {
        text-align: center;
        order: 2;
    }
    .cta-first
    {
        text-align: center;
        margin-bottom: 20px;
        order: 1;
    }
    button
    {
        min-width: auto!important;
        width: 100%!important;
    }
  }